/**
 * Returns the Human Readable Value(HRV) for a given type.
 *
 * @param {ResourceType} type
 */
export function getHRVForResourceType(type) {
  const values = {
    [ResourceType.COMPUTER]: "Computer",
    [ResourceType.MOBILE_PHONE]: "Mobile phone",
    [ResourceType.LICENSE]: "License",
    [ResourceType.HEADSET]: "Headset",
    [ResourceType.PERIPHERAL]: "Peripheral",
    [ResourceType.UNKNOWN]: "Unknown",
  };

  return values[type];
}

/**
 * @enum {string} ResourceType
 */
export const ResourceType = {
  COMPUTER: "COMPUTER",
  MOBILE_PHONE: "MOBILE_PHONE",
  LICENSE: "LICENSE",
  HEADSET: "HEADSET",
  PERIPHERAL: "PERIPHERAL",
  UNKNOWN: "UNKNOWN",
};

/**
 * @class Resource
 */
export class Resource {
  /**
   * @param {{
   *  id: string;
   *  type: ResourceType;
   *  manufacturer: string
   *  model: string;
   *  officeLocation: firestore.DocumentReference<firestore.DocumentData>
   * }} parameters
   */
  constructor({
    id = "",
    type = "",
    manufacturer = "",
    model = "",
    officeLocation,
  } = {}) {
    this.id = id;
    this.type = type;
    this.manufacturer = manufacturer;
    this.model = model;
    this.officeLocation = officeLocation;
  }

  toString() {
    return [this.manufacturer, this.model]
      .filter((value) => value !== "" && value !== null && value !== undefined)
      .join(" ");
  }
}

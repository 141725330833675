<template>
  <div id="app">
    <section class="hero is-fullheight is-dark">
      <div class="hero-header" />
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered is-mobile">
            <div class="column is-narrow has-text-centered">
              <figure class="image is-app-logo">
                <heaven-logo />
              </figure>

              <h1 class="title">Welcome to <br />Heaven-{{ $appVersion }}!</h1>
              <h2 class="subtitle is-5">
                We hope <strong>you</strong><br />have a pleasant stay.
              </h2>

              <div class="buttons is-centered">
                <sign-in-button>Enter Heaven</sign-in-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hero-footer" />
    </section>
  </div>
</template>

<script>
import HeavenLogo from "@/assets/heaven-logo.svg";
import SignInButton from "@/components/auth/SignInButton";
import { removeClass } from "@/utils";

export default {
  name: "SignInApp",
  components: { SignInButton, HeavenLogo },
  created() {
    removeClass(document.body, "has-navbar-fixed-bottom");
  },
};
</script>

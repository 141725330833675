import { collection, doc, getFirestore, addDoc } from "firebase/firestore";
import { DateTime } from "luxon";
import { LogEntry, OperationType } from "@/models/LogEntry";
import { AuthService } from "@/services/AuthService";

function getUserReference(db) {
  return doc(db, "users", AuthService.getUserId());
}

/**
 *
 * @param {LogEntryData} logEntryData;
 * @param {string} operation;
 */
async function log(logEntryData, operation) {
  const db = getFirestore();
  const logEntry = new LogEntry({
    ...logEntryData,
    userRef: getUserReference(db),
    operation,
    timestamp: DateTime.local().toISO(),
  });
  await addDoc(collection(db, "changelog"), { ...logEntry });
}

export const LogService = new (class {
  async create(logEntryData) {
    await log(logEntryData, OperationType.CREATE);
  }
  async update(logEntryData) {
    await log(logEntryData, OperationType.UPDATE);
  }
  async delete(logEntryData) {
    await log(logEntryData, OperationType.DELETE);
  }
})();

import { DateTime } from "luxon";
import { Resource, ResourceType, Retailer, User } from "@/models";
import { DocumentResolver } from "@/models/DocumentResolver";

/**
 * @enum {string} AssetStatus
 */
export const AssetStatus = {
  ASSIGNED: "ASSIGNED",
  UNASSIGNED: "UNASSIGNED",
  RETIRED: "RETIRED",
  RECYCLED: "RECYCLED",
  PURCHASED: "PURCHASED",
  NEW: "NEW",
};

/**
 * Returns the Human Readable Value(HRV) for a given status.
 *
 * @param {AssetStatus} status
 */
export function getHRVForAssetStatus(status) {
  const values = {
    [AssetStatus.ASSIGNED]: "Assigned",
    [AssetStatus.UNASSIGNED]: "Unassigned",
    [AssetStatus.RETIRED]: "Retired",
    [AssetStatus.RECYCLED]: "Recycled",
    [AssetStatus.PURCHASED]: "Purchased",
    [AssetStatus.NEW]: "New",
  };
  return values[status];
}

/**
/* @param {string} type
 */
export function getIconForResourceType(type) {
  switch (type) {
    case ResourceType.COMPUTER:
      return "laptop-o";
    case ResourceType.MOBILE_PHONE:
      return "mobile";
    case ResourceType.LICENSE:
      return "file-o";
    case ResourceType.HEADSET:
      return "headphones";
    case ResourceType.PERIPHERAL:
      return "rocket";
    case ResourceType.UNKNOWN:
    default:
      return "rocket";
  }
}

/**
 * @class Resource
 */
export class Asset extends DocumentResolver {
  /**
   * @param {{
   *  id: string;
   *  userRef: firestore.DocumentReference<firestore.DocumentData>;
   *  resourceRef: firestore.DocumentReference<firestore.DocumentData>;
   *  officeLocation: firestore.DocumentReference<firestore.DocumentData>;
   *  retailerRef: firestore.DocumentReference<firestore.DocumentData> | null;
   *  dateOfPurchase: string;
   *  comment: string;
   *  serialNumber: string | null;
   *  yearsOfWarranty: number;
   *  status: AssetStatus;
   *  qr: String;
   *  loaner: boolean;
   *  isOfficeAsset: boolean;
   *  isThirdPartyAsset: boolean
   *  price: number | null;
   *  orderNumber: string | null;
   *  articleNumber: string | null;
   *
   * }} parameters
   */
  constructor({
    id = "",
    userRef,
    resourceRef,
    officeLocation,
    dateOfPurchase,
    comment = "",
    serialNumber = "",
    yearsOfWarranty,
    status,
    qr,
    loaner = false,
    estimatedReturnDate = null,
    guest = null,
    isOfficeAsset = false,
    isThirdPartyAsset = false,
    retailerRef = null,
    price = null,
    orderNumber = "",
    articleNumber = "",
  } = {}) {
    super();
    this.id = id;
    this.userRef = userRef;
    this.resourceRef = resourceRef;
    this.officeLocation = officeLocation;
    this.dateOfPurchase = dateOfPurchase;
    this.comment = comment;
    this.serialNumber = serialNumber;
    this.yearsOfWarranty = yearsOfWarranty;
    this.status = status;
    this.qr = qr;
    this.loaner = loaner;
    this.estimatedReturnDate = estimatedReturnDate;
    this.guest = guest;
    this.isOfficeAsset = isOfficeAsset;
    this.isThirdPartyAsset = isThirdPartyAsset;
    this.retailerRef = retailerRef;
    this.price = price;
    this.orderNumber = orderNumber;
    this.articleNumber = articleNumber;
  }

  /**
   * Returns the resolved ref data in an array structure.
   *
   * The return value can be destructured like:
   *
   * `const [resource, user, retailer] = await asset.resolveRefs();`
   *
   * @function
   * @async
   * @returns {Promise<[Resource, User, Retailer]>} data
   */
  async resolveRefs() {
    return Promise.all([
      this.resolveRef(Resource, "resourceRef"),
      this.resolveRef(User, "userRef"),
      this.resolveRef(Retailer, "retailerRef"),
    ]);
  }

  getExpiryDate() {
    return DateTime.fromISO(this.dateOfPurchase)
      .plus({
        years: this.yearsOfWarranty,
      })
      .toISODate();
  }
}

import { getDoc } from "firebase/firestore";

export class DocumentResolver {
  /**
   * @function
   * @async
   * @template C
   * @template P extends keyof this
   * @param {C} ClassToResolve
   * @param {P} property
   * @returns {Promise<C>} data
   */
  async resolveRef(ClassToResolve, property) {
    /**
     * @type {DocumentSnapshot<DocumentData> | firestore.QueryDocumentSnapshot<firestore.DocumentData>}
     */
    const resolvedRef = this[property] ? await getDoc(this[property]) : null;
    return resolvedRef
      ? new ClassToResolve({
          id: resolvedRef.id,
          ...resolvedRef.data(),
        })
      : new ClassToResolve();
  }

  /**
   * @function
   * @async
   * @template C
   * @template P extends keyof this
   * @param {C} ClassToResolve
   * @param {P} property
   * @returns {Promise<C>} data
   */
  async resolveListOfRefs(ClassToResolve, property) {
    /**
     * @type {firestore.DocumentSnapshot<firestore.DocumentData>[] | firestore.QueryDocumentSnapshot<firestore.DocumentData>[]}
     */
    const resolvedRefs = await Promise.all(
      this[property].map(async (ref) => getDoc(ref)),
    );

    if (resolvedRefs.length !== 0) {
      return resolvedRefs.map(
        (ref) =>
          new ClassToResolve({
            id: ref.id,
            ...ref.data(),
          }),
      );
    } else {
      return [];
    }
  }
}

import { DateTime } from "luxon";
import { JetbrainsAssetStatus } from "@/models/JetbrainsAsset";

/**
 * @param {string} lastAssignmentDate
 * @param {string} status
 * @return {boolean}
 */
export function jetbrainsLicenseCanBeRevoked(lastAssignmentDate, status) {
  if (!lastAssignmentDate) {
    return true;
  }

  const assignmentDate = DateTime.fromISO(lastAssignmentDate);
  const currentDate = DateTime.local();
  const diff = currentDate.diff(assignmentDate, "days").days;

  return (
    status === JetbrainsAssetStatus.UNASSIGNED ||
    (diff >= 30 && status === JetbrainsAssetStatus.ASSIGNED)
  );
}

/**
 * @class Retailer
 */

import { DocumentResolver } from "@/models/DocumentResolver";

export class Retailer extends DocumentResolver {
  /**
   * @param {{
   * id: string;
   * officeLocation: firestore.DocumentReference<firestore.DocumentData>;
   * name: string;
   * accountName: string;
   * url: string;
   * isActive: boolean
   * }} parameters
   */
  constructor({
    id = "",
    officeLocation,
    name = "",
    accountName = "",
    url = "",
    isActive = true,
  } = {}) {
    super();
    this.id = id;
    this.officeLocation = officeLocation;
    this.name = name;
    this.accountName = accountName;
    this.url = url;
    this.isActive = isActive;
  }
}

import { getDoc, getDocs, query, where } from "firebase/firestore";
// eslint-disable-next-line no-unused-vars
import { User, UserRole, UsersFilter } from "@/models";
import { AuthService } from "@/services";
import { FirestoreService } from "@/services/FirestoreService";

export class UsersFirestoreService extends FirestoreService {
  async getThisUser(filter) {
    let resolvedDocs;
    let q = this.getDocById(AuthService.getUserId());

    if (filter.role && filter.role !== UserRole.USER) {
      resolvedDocs = { docs: [] };
    } else {
      const user = await getDoc(q);
      resolvedDocs = { docs: [{ id: user.id, data: () => user.data() }] };
    }
    return resolvedDocs;
  }

  async getUsers() {
    if (AuthService.isAdmin()) {
      return this.getAll(true);
    } else {
      const officeLocation = await this.getUserOffice();
      return this.getAllWhere("officeLocation", "==", officeLocation);
    }
  }

  /**
   * @param location
   */
  async getUsersByLocation(location) {
    if (AuthService.isAdmin()) {
      return this.getAllWhere("officeLocation", "==", location);
    }
    return [];
  }
  async getLeaders() {
    return this.getAllWhere("role", "in", [
      UserRole.ADMIN,
      UserRole.OFFICEADMIN,
    ]);
  }

  /**
   *
   * @param {User} user
   */
  async getOfficeLeaders(user) {
    return await this.getWithWhereClauses([
      where("administeredOffices", "array-contains-any", [user.officeLocation]),
      where("disabled", "!=", true),
      where("role", "in", [UserRole.ADMIN, UserRole.OFFICEADMIN]),
    ]);
  }

  /**
   *
   * @param {String} leaderId
   */
  async getUsersByLeaderId(leaderId) {
    return this.getAllWhere("leader", "==", this.getDocById(leaderId));
  }

  /**
   * @async
   * @param {string} email
   * @return {Promise<User>} User
   */
  async getUserByEmail(email) {
    const q = query(this.getCollection(), where("email", "==", email));
    const resolvedDocs = await getDocs(q);

    if (resolvedDocs.size === 1) {
      return this.toDocClass(resolvedDocs.docs[0]);
    }

    return new User();
  }

  /**
   * @async
   * @param {string} email
   * @return {Promise<firestore.DocumentReference<firestore.DocumentData> | null>} DocumentReference or null
   */

  async getUserDocByEmail(email) {
    const q = query(this.getCollection(), where("email", "==", email));
    const resolvedDocs = await getDocs(q);

    if (resolvedDocs.size === 1) {
      return resolvedDocs.docs[0].ref;
    }

    return null;
  }

  /**
   *
   * @param {UsersFilter} filter
   */
  async getAllFromUsersFilter(filter) {
    let resolvedDocs;

    if (AuthService.isUser()) {
      resolvedDocs = await this.getThisUser(filter);
    } else {
      const whereClauses = [];

      if (filter.disabled) {
        whereClauses.push(where("disabled", "==", true));
      } else {
        whereClauses.push(where("disabled", "==", false));
      }

      if (filter.location.length > 0) {
        const officeObjectArray = filter.location.map((office) =>
          office.toRef(),
        );
        whereClauses.push(where("officeLocation", "in", officeObjectArray));
      }

      if (AuthService.getUserRole() === UserRole.OFFICEADMIN) {
        const user = await this.getOne(AuthService.getUserId());

        if (user.administeredOffices.length > 1) {
          whereClauses.push(
            where("officeLocation", "in", user.administeredOffices),
          );
        } else {
          whereClauses.push(where("officeLocation", "==", user.officeLocation));
        }
      }

      if (filter.role) {
        whereClauses.push(where("role", "==", filter.role));
      }

      if (filter.leader) {
        if (filter.leader === "unassigned") {
          whereClauses.push(where("leader", "==", null));
        } else {
          whereClauses.push(
            where("leader", "==", this.getDocById(filter.leader)),
          );
        }
      }
      const q = query(this.getCollection(), ...whereClauses);
      resolvedDocs = await getDocs(q);
    }

    let users = this.toDocClasses(resolvedDocs);

    // ? Perform substring comparison in the client as Firestore do not support this natively.
    if (filter.searchTerm) {
      users = users.filter((user) => {
        const isDisplayNameMatch = user.displayName
          .toLowerCase()
          .includes(filter.searchTerm.toLowerCase());

        const isEmailMatch = user.email
          .toLowerCase()
          .includes(filter.searchTerm.toLowerCase());

        return isDisplayNameMatch || isEmailMatch;
      });
    }

    return users;
  }

  async getUserOffice() {
    const user = await this.getOne(AuthService.getUserId());
    return user.officeLocation;
  }
}

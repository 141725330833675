import { SupportRequestType, SupportRequestStatus } from "@/models";
import { AssetsService, AuthService, UsersService } from "@/services";
import { FirestoreService } from "@/services/FirestoreService";

export class SupportRequestsFirestoreService extends FirestoreService {
  async getRequests() {
    if (AuthService.isAdmin()) {
      return this.getAll();
    } else {
      return this.getUserRequests(AuthService.getUserId());
    }
  }

  /**
   * @param {string} userId
   */
  // TODO: Does this actually get the requests?
  async getUserRequests(userId) {
    return this.getAllWhere(
      "requestedByUserRef",
      "==",
      UsersService.getDocById(userId),
    );
  }

  /**
   * @private
   * @param {string} assetId
   */
  async _getRequestsByAssetId(assetId) {
    return this.getAllWhere(
      "assetRef",
      "==",
      AssetsService.getDocById(assetId),
    );
  }

  /**
   * Reject support requests linked with the Asset id
   * @param {string} assetId
   */
  async deleteAllRequestsByAssetId(assetId) {
    const supportRequests = await this._getRequestsByAssetId(assetId);
    supportRequests.map((request) => this.deleteOne(request.id));
  }

  isApprovedDeletionRequest(supportRequest) {
    return (
      supportRequest.type === SupportRequestType.ASSET_DELETION &&
      supportRequest.status === SupportRequestStatus.APPROVED
    );
  }
}

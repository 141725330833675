import {
  collection,
  getDocs,
  query,
  where,
  updateDoc,
} from "firebase/firestore";
import { UserRole } from "@/models";
import { AuthService, UsersService } from "@/services";
import { FirestoreService } from "@/services/FirestoreService";

export class OfficesFirestoreService extends FirestoreService {
  /**
   * Get all offices without auth requirements
   */
  async getAll(skipAuth = false) {
    const user = await UsersService.getOne(AuthService.getUserId());

    if (skipAuth || AuthService.isSuperAdmin()) {
      const resolvedDocs = await getDocs(this.getCollection());
      return this.toDocClasses(resolvedDocs);
    } else if (AuthService.isOfficeAdmin) {
      return user.administeredOffices;
    } else if (AuthService.isUser) {
      return user.officeLocation;
    }
  }

  /**
   * Delete an office-group, only if user is super admin
   * Will clear every reference to the office in other tables
   *
   * @param {string} id;
   */
  async deleteOne(id) {
    if (AuthService.getUserRole() === UserRole.ADMIN) {
      let reference = this.getDocById(id);
      let collectionsToCleanOfRef = [
        "users",
        "assets",
        "resources",
        "support-requests",
        "keys",
        "retailers",
      ];

      for (const colName of collectionsToCleanOfRef) {
        var collectionsQuery = query(
          collection(this.db, colName),
          where("officeLocation", "==", reference),
        );
        const querySnapshot = await getDocs(collectionsQuery);
        querySnapshot.forEach((doc) =>
          updateDoc(doc.ref, { officeLocation: null }),
        );
      }

      var administeredOfficesQuery = query(
        collection(this.db, "users"),
        where("administeredOffices", "array-contains", reference),
      );
      const administeredOfficesSnapshot = await getDocs(
        administeredOfficesQuery,
      );
      administeredOfficesSnapshot.forEach((doc) => {
        let newOffices = doc
          .data()
          .administeredOffices.filter((office) => office.id !== reference.id);
        updateDoc(doc.ref, {
          administeredOffices: newOffices,
        });
      });

      var officeGroupQuery = query(
        collection(this.db, "office-groups"),
        where("offices", "array-contains", reference),
      );
      const querySnapshot = await getDocs(officeGroupQuery);
      querySnapshot.forEach((doc) => {
        let newOffices = doc
          .data()
          .offices.filter((office) => office.id !== reference.id);
        updateDoc(doc.ref, {
          offices: newOffices,
        });
      });
      await super.deleteOne(id);
    }
  }
}

<template>
  <i class="im" v-bind:class="iconName" />
</template>

<script>
export default {
  name: "MonstrIcon",
  props: {
    icon: String,
  },
  computed: {
    iconName() {
      return `im-${this.icon}`;
    },
  },
};
</script>

import { getDocs, query, where, or, and } from "firebase/firestore";
// eslint-disable-next-line no-unused-vars
import { AssetsFilter, UserRole } from "@/models";
import {
  AssetsService,
  AuthService,
  CacheService,
  ResourcesService,
  UsersService,
} from "@/services";
import { filterByUserRef } from "@/utils";

class AssetsUtilsClass {
  /**
   * @async
   * @private
   * @param {firestore.DocumentReference<firestore.DocumentData>[]} resourceRefs
   * @return {Promise<String[]>} resourceReferencesAsString
   */
  async getResourceReferences(resourceRefs) {
    return resourceRefs
      .map((a) => CacheService.getResource(a))
      .map((r) => r.id);
  }

  /**
   * @async
   * @private
   * @param {firestore.DocumentReference<firestore.DocumentData>[]} retailerRefs
   * @return {Promise<String[]>} retailerReferencesAsString
   */
  async getRetailerReferences(retailerRefs) {
    return retailerRefs
      .map((a) => CacheService.getRetailer(a))
      .map((r) => r.id);
  }

  /**
   * @async
   * @private
   * @param {Resource[]} resources
   * @param {Asset[]} assets
   * @return {Promise<Asset[]>} Assets[]
   */
  async filterAssetResourceReferences(resources, assets) {
    let resourceRefs = assets.map((a) => a.resourceRef);
    let resourceReferencesAsString =
      await this.getResourceReferences(resourceRefs);
    let resourceIds = resources.map((resource) => resource.id);

    resourceReferencesAsString = resourceReferencesAsString.filter(
      (assetResourceRef) => {
        return resourceIds.includes(assetResourceRef);
      },
    );

    return assets.filter((a) =>
      resourceReferencesAsString.includes(a.resourceRef.id),
    );
  }

  /**
   * @async
   * @private
   * @param {Resource[]} retailers
   * @param {Asset[]} assets
   * @return {Promise<Asset[]>} Assets[]
   */
  async filterAssetRetailerReference(retailers, assets) {
    let retailerRefs = assets.map((a) => a.retailerRef);
    let retailerReferencesAsString =
      await this.getRetailerReferences(retailerRefs);

    let retailerIds = retailers.map((retailer) => retailer.id);

    retailerReferencesAsString = retailerReferencesAsString.filter(
      (assetRetailerRef) => {
        return retailerIds.includes(assetRetailerRef);
      },
    );
    assets = assets.map((asset) => {
      if (asset.retailerRef === null || asset.retailerRef === undefined) {
        asset.retailerRef = "";
      }
      return asset;
    });

    return assets.filter((asset) =>
      retailerReferencesAsString.includes(asset.retailerRef.id),
    );
  }

  /**
   *
   * @param {AssetsFilter} assetsFilter
   */
  async getAllFromAssetsFilter(assetsFilter) {
    const whereClauses = [];

    if (!assetsFilter.librarySearch && AuthService.isUser()) {
      const userLocation = await UsersService.getUserOffice();
      whereClauses.push(
        or(
          where(
            "userRef",
            "==",
            UsersService.getDocById(AuthService.getUserId()),
          ),
          and(
            where("isOfficeAsset", "==", true),
            where("officeLocation", "==", userLocation),
          ),
        ),
      );
    }

    if (assetsFilter.locationSearch.length > 0) {
      const officeObjectArray = assetsFilter.locationSearch.map((office) =>
        office.toRef(),
      );
      whereClauses.push(where("officeLocation", "in", officeObjectArray));
    }

    if (AuthService.getUserRole() === UserRole.OFFICEADMIN) {
      const user = await UsersService.getOne(AuthService.getUserId());

      if (user.administeredOffices.length > 1) {
        whereClauses.push(
          where("officeLocation", "in", user.administeredOffices),
        );
      } else {
        whereClauses.push(where("officeLocation", "==", user.officeLocation));
      }
    }

    if (assetsFilter.resourceId) {
      whereClauses.push(
        where(
          "resourceRef",
          "==",
          ResourcesService.getDocById(assetsFilter.resourceId),
        ),
      );
    }

    if (assetsFilter.statusSearch.length) {
      whereClauses.push(where("status", "in", assetsFilter.statusSearch));
    }

    if (assetsFilter.fromDateOfPurchaseSearch) {
      whereClauses.push(
        where("dateOfPurchase", ">=", assetsFilter.fromDateOfPurchaseSearch),
      );
    }

    if (assetsFilter.toDateOfPurchaseSearch) {
      whereClauses.push(
        where("dateOfPurchase", "<=", assetsFilter.toDateOfPurchaseSearch),
      );
    }

    if (assetsFilter.qr) {
      whereClauses.push(where("qr", "==", assetsFilter.qr));
    }

    if (assetsFilter.librarySearch) {
      whereClauses.push(where("loaner", "==", assetsFilter.librarySearch));
    }

    const q = query(AssetsService.getCollection(), and(...whereClauses));
    const resolvesDocs = await getDocs(q);
    let assets = AssetsService.toDocClasses(resolvesDocs);

    if (assetsFilter.assigneeSearch) {
      assets = await filterByUserRef(
        assetsFilter.assigneeSearch.toLowerCase(),
        assets,
      );
    }

    if (assetsFilter.resourceSearch) {
      let resources = CacheService.getResources();
      resources = resources.filter((resource) => {
        return (resource.manufacturer + " " + resource.model)
          .toLowerCase()
          .includes(assetsFilter.resourceSearch.toLowerCase());
      });

      assets = await this.filterAssetResourceReferences(resources, assets);
    }

    if (assetsFilter.resourceType) {
      let resources = CacheService.getResources();
      resources = resources.filter((resource) => {
        return resource.type.includes(assetsFilter.resourceType.toUpperCase());
      });

      assets = await this.filterAssetResourceReferences(resources, assets);
    }

    if (assetsFilter.retailerSearch) {
      let retailers = CacheService.getRetailers();
      retailers = retailers.filter((retailer) => {
        return retailer.name
          .toLowerCase()
          .includes(assetsFilter.retailerSearch.toLowerCase());
      });

      assets = await this.filterAssetRetailerReference(retailers, assets);
    }

    return assets;
  }
}

export const AssetsUtils = new AssetsUtilsClass();

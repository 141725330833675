// eslint-disable-next-line no-unused-vars
import { UserRole, Asset } from "@/models";
import { AuthService, JetbrainsAssetsService, UsersService } from "@/services";
import { FirestoreService } from "@/services/FirestoreService";

export class AssetsFirestoreService extends FirestoreService {
  /**
   * @async
   * @return {Promise<Asset[]>}
   */
  async getAssets() {
    let assets, jetBrainsAssets;

    if (AuthService.isAdmin()) {
      assets = await this.getAll();
      jetBrainsAssets = await JetbrainsAssetsService.getAllAssets(
        AuthService.getUserId(),
      );
    } else {
      assets = await this.getUserAssets(AuthService.getUserId());
      jetBrainsAssets = await JetbrainsAssetsService.getAllAssets(
        AuthService.getUserId(),
        { role: UserRole.USER },
      );
    }

    return [...assets, ...jetBrainsAssets];
  }

  /**
   * @async
   * @param {string} userId
   */
  async getUserAssets(userId) {
    return await this.getAllWhere(
      "userRef",
      "==",
      UsersService.getDocById(userId),
    );
  }

  getLoaners() {
    return this.getAllWhere("loaner", "==", true);
  }
}

import { getAuth, onAuthStateChanged } from "firebase/auth";
import Vue from "vue";
import App from "@/App";
import InvalidDomainApp from "@/InvalidDomainApp";
import LoadingApp from "@/LoadingApp";
import SignInApp from "@/SignInApp";
import router from "@/router";
import { CacheService, UsersService } from "@/services";
import { AuthUtils } from "@/utils";

function render(app, router = undefined) {
  new Vue({
    router,
    render: (h) => h(app),
  }).$mount("#app");
}

export function createApp(renderer = render) {
  renderer(LoadingApp);

  const auth = getAuth();
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      const userData = await UsersService.getOne(user.uid);
      if (user.email.endsWith("softhouse.se") && !userData.disabled) {
        await AuthUtils.updateUserState();
        await CacheService.create();
        renderer(App, router);
      } else {
        renderer(InvalidDomainApp, router);
      }
    } else {
      renderer(SignInApp);
    }
  });
}

import { doc, getDocs, query, where, writeBatch } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { isEmpty } from "lodash";
import { Asset, AssetStatus, ResourceType, UserRole } from "@/models";
import { JetbrainsAsset, JetbrainsAssetStatus } from "@/models/JetbrainsAsset";
import { JetbrainsAssetsFilter } from "@/models/JetbrainsAssetsFilter";
import { AuthService } from "@/services/AuthService";
import { FirestoreService } from "@/services/FirestoreService";
import { UsersService } from "@/services/UsersService";
import {
  jetbrainsLicenseCanBeRevoked,
  filterByUserRef,
  getISODate,
} from "@/utils";

const ApiFunctionNames = {
  ASSIGN: "assignOneJetbrainsAsset",
  UNASSIGN: "unassignOneJetbrainsAsset",
};

export class JetbrainsAssetsFirestoreService extends FirestoreService {
  /**
   * @private
   * @async
   * @param {string} functionName
   * @param {string | object} functionArgument
   * @return {Promise<void>}
   */
  async executePostRequest(functionName, functionArgument) {
    const functions = getFunctions(undefined, "europe-west3");
    const sendRequest = httpsCallable(functions, functionName);
    await sendRequest(functionArgument);

    let assetId;

    if (functionName === ApiFunctionNames.ASSIGN) {
      assetId = functionArgument.asset.id;
    } else {
      assetId = functionArgument.id;
    }

    const updatedAsset = await this.getOneAsset(assetId);
    await this.updateOne(updatedAsset);
  }

  /**
   * @private
   * @async
   * @param {Asset[]}assets
   * @return {Promise<void>}
   */
  async addToDb(assets) {
    const batch = writeBatch(this.db);

    assets.forEach((asset) => {
      const jetbrainsAsset = new JetbrainsAsset(asset);
      jetbrainsAsset.convertFromAsset(asset);

      const jetbrainsAssetRef = doc(
        this.db,
        this.collectionName,
        jetbrainsAsset.id,
      );

      batch.set(jetbrainsAssetRef, { ...jetbrainsAsset });
    });

    await batch.commit();
  }

  /**
   * @private
   * @async
   * @param {*[]} response
   * @return {Promise<Asset[]|[]>}
   */
  async mapResponseToAssets(response) {
    const assets = await Promise.all(
      response.map(async (resp) => {
        return await this.mapToAsset(resp);
      }),
    );
    await this.addToDb(assets);

    return assets.length ? assets : [];
  }

  /**
   * @private
   * @async
   * @param {JetbrainsAssetsFilter} filter
   * @returns {Promise<Asset[]|[]>}
   */
  async filterAssets(filter) {
    if (filter.assetType && filter.assetType !== ResourceType.LICENSE) {
      return [];
    }

    const whereClauses = [];

    if (AuthService.isUser()) {
      whereClauses.push(
        where(
          "userRef",
          "==",
          UsersService.getDocById(AuthService.getUserId()),
        ),
      );
    }

    if (AuthService.getUserRole() !== UserRole.ADMIN) {
      const userOfficeLocation = await UsersService.getUserOffice();
      whereClauses.push(where("officeLocation", "==", userOfficeLocation));
    }

    if (filter.status.length) {
      whereClauses.push(where("status", "in", filter.status));
    }

    if (filter.fromDateOfPurchase) {
      whereClauses.push(
        where("assignmentDate", ">=", filter.fromDateOfPurchase),
      );
    }

    if (filter.toDateOfPurchase) {
      whereClauses.push(where("assignmentDate", "<=", filter.toDateOfPurchase));
    }

    if (filter.locationSearch.length > 0) {
      const officeObjectArray = filter.locationSearch.map((office) =>
        office.toRef(),
      );
      whereClauses.push(where("officeLocation", "in", officeObjectArray));
    }

    const q = query(this.getCollection(), ...whereClauses);
    const resolvedDocs = await getDocs(q);

    let filteredAssets = this.toDocClasses(resolvedDocs);

    if (filter.assetName) {
      filteredAssets = filteredAssets.filter((asset) =>
        asset.name.toLowerCase().includes(filter.assetName.toLowerCase()),
      );
    }

    if (filter.employeeName) {
      filteredAssets = await filterByUserRef(
        filter.employeeName.toLowerCase(),
        filteredAssets,
      );
    }

    return filteredAssets;
  }

  /**
   * @private
   * @async
   * @param {Object} thirdPartyAsset
   * @return {Promise<Asset>}
   */
  async mapToAsset(thirdPartyAsset) {
    const status = thirdPartyAsset.isAvailableToAssign
      ? AssetStatus.UNASSIGNED
      : AssetStatus.ASSIGNED;
    let userRef = "";
    let officeLocation = "";

    if (status === AssetStatus.ASSIGNED && thirdPartyAsset.assignee?.email) {
      const user = await UsersService.getUserDocByEmail(
        thirdPartyAsset.assignee?.email,
      );

      if (user) {
        userRef = user;
        officeLocation = (
          await UsersService.getUserByEmail(thirdPartyAsset.assignee.email)
        ).officeLocation;
      }
    }

    const asset = new Asset({
      id: thirdPartyAsset.licenseId,
      status,
      userRef,
      officeLocation,
      isThirdPartyAsset: true,
    });

    asset.name = thirdPartyAsset.product.name;
    asset.productCode = thirdPartyAsset.product.code;
    asset.employeeEmail = thirdPartyAsset.assignee?.email
      ? thirdPartyAsset.assignee.email
      : "";
    asset.isAutomaticallyRenewed = thirdPartyAsset.subscription
      ?.isAutomaticallyRenewed
      ? thirdPartyAsset.subscription?.isAutomaticallyRenewed
      : false;
    asset.validUntil = getISODate(thirdPartyAsset.subscription?.validUntilDate);
    asset.canBeRevoked = jetbrainsLicenseCanBeRevoked(
      thirdPartyAsset.lastSeen?.lastAssignmentDate,
      status,
    );
    asset.assignmentDate = getISODate(
      thirdPartyAsset.lastSeen?.lastAssignmentDate,
    );
    asset.teamId = thirdPartyAsset.team.id;
    return asset;
  }

  /**
   * @private
   * @param {JetbrainsAsset[]} jetbrainsAssets
   * @returns {Asset[]}
   */
  mapJetbrainsAssetsToAssets(jetbrainsAssets) {
    return jetbrainsAssets.map((jetbrainsAsset) => {
      const asset = new Asset({
        id: jetbrainsAsset.id,
        status: jetbrainsAsset.status,
        userRef: jetbrainsAsset.userRef,
        isThirdPartyAsset: true,
      });

      asset.name = jetbrainsAsset.name;
      asset.productCode = jetbrainsAsset.productCode;
      asset.employeeEmail = jetbrainsAsset.employeeEmail;
      asset.isAutomaticallyRenewed = jetbrainsAsset.isAutomaticallyRenewed;
      asset.validUntil = jetbrainsAsset.validUntil;
      asset.canBeRevoked = jetbrainsAsset.canBeRevoked;
      asset.assignmentDate = jetbrainsAsset.assignmentDate;
      asset.teamId = jetbrainsAsset.teamId;
      return asset;
    });
  }

  /**
   * @async
   * @param {string} userId
   * @param {object} filter
   * @param {boolean} fetchFromDb
   * @return {Promise<[Asset]|[]>}
   */
  async getAllAssets(userId, filter = {}, fetchFromDb = false) {
    if (
      filter?.resourceId ||
      filter?.qr ||
      filter?.librarySearch ||
      filter?.retailerSearch
    ) {
      // These fields do currently not exist for Jetbrains Assets.
      // So for now, simply return none.
      return [];
    }

    const userCaller = {};
    userCaller.userId = userId;
    userCaller.filter = filter;

    if (fetchFromDb) {
      if (isEmpty(filter)) {
        const assets = await this.getAll();
        return this.mapJetbrainsAssetsToAssets(assets);
      }

      const filteredAssets = await this.filterAssets(
        new JetbrainsAssetsFilter(filter),
      );
      return this.mapJetbrainsAssetsToAssets(filteredAssets);
    }

    const functions = getFunctions(undefined, "europe-west3");
    const sendRequest = httpsCallable(functions, "getAllJetbrainsAssets");
    const response = await sendRequest(userCaller);

    return await this.mapResponseToAssets(response.data);
  }

  /**
   * @async
   * @param {string} id
   * @return {Promise<JetbrainsAsset>}
   */
  async getOneAsset(id) {
    const functions = getFunctions(undefined, "europe-west3");
    const sendRequest = httpsCallable(functions, "getOneJetbrainsAsset");
    const response = await sendRequest({ id });

    const jetbrainsAsset = new JetbrainsAsset();

    if (response) {
      jetbrainsAsset.convertFromResponse(response.data);
    }
    return jetbrainsAsset;
  }

  /**
   * @async
   * @param {JetbrainsAsset} asset
   * @param {User} user
   * @throws Will throw error if asset already is assigned
   * @return {Promise<void>}
   */
  async assignOneAsset(asset, user) {
    const currentAsset = await this.getOne(asset.id);

    if (currentAsset.status !== JetbrainsAssetStatus.UNASSIGNED) {
      throw new Error("Asset is already assigned");
    }

    const payload = {
      asset: {
        id: asset.id,
        teamId: asset.teamId,
        productCode: asset.productCode,
      },
      user: {
        displayName: user.displayName,
        email: user.email,
      },
    };

    try {
      await this.executePostRequest(ApiFunctionNames.ASSIGN, payload);
    } catch (e) {
      throw new Error("Something went wrong when updating asset");
    }
  }

  /**
   * @async
   * @param {string} id
   * @throws Will throw error if asset already is unassigned
   * @return {Promise<void>}
   */
  async unassignOneAsset(id) {
    const currentAsset = await this.getOne(id);

    if (currentAsset.status !== JetbrainsAssetStatus.ASSIGNED) {
      throw new Error("Asset is already unassigned");
    }
    try {
      await this.executePostRequest(ApiFunctionNames.UNASSIGN, { id });
    } catch (e) {
      throw new Error("Something went wrong when updating asset");
    }
  }
}

import { getDocs } from "firebase/firestore";
import { UserRole } from "@/models";
import { AuthService } from "@/services/AuthService";
import { FirestoreService } from "@/services/FirestoreService";

export class OfficeGroupsFirestoreService extends FirestoreService {
  /**
   * Gets all office-groups without auth requirements
   *
   */
  async getAllOfficeGroups() {
    const resolvedDocs = await getDocs(this.getCollection());
    return this.toDocClasses(resolvedDocs);
  }

  /**
   * Delete an office-group, only if user is super admin
   *
   * @param {string} id;
   */
  async deleteOne(id) {
    if (AuthService.getUserRole() === UserRole.ADMIN) {
      super.deleteOne(id);
    }
  }
}

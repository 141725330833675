import { DateTime } from "luxon";
import { User } from "@/models";
import { DocumentResolver } from "@/models/DocumentResolver";

/**
 * @enum {string} ChecklistAccountItems
 */
export const AccountItems = {
  JIRA: "Jira",
  GITHUB: "Github",
  BITBUCKET: "Bitbucket",
  VPN: "Vpn",
  SLACK: "Slack",
};
/**
 * @enum {string} ChecklistEssentialItems
 */
export const EssentialItems = {
  KEYS: "Keys",
  ACCESS_CARD: "Access card",
  PARKING_PERMIT: "Parking permit",
  CREDIT_CARD: "Credit card",
};

/**
 * @enum {string} AccountStatus
 */
export const AccountStatus = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  NOT_APPLICABLE: "NOT APPLICABLE",
};

/**
 * @enum {string} EssentialsStatus
 */
export const EssentialsStatus = {
  RETURNED: "RETURNED",
  MISSING: "MISSING",
  NOT_APPLICABLE: "NOT APPLICABLE",
};

/**
 * @class OffboardChecklist
 */
export class OffboardChecklist extends DocumentResolver {
  /**
   * @param {{
   * id: string,
   * userRef: firestore.DocumentReference<firestore.DocumentData>,
   * accounts: {
   *   AccountItems: AccountStatus,
   * },
   * essentials: {
   *   EssentialItems: EssentialsStatus,
   * },
   * assets: { [id: string ]: string | firestore.DocumentReference<firestore.DocumentData> },
   * offboardByUserRef: firestore.DocumentReference<firestore.DocumentData>,
   * timestamp: string,
   * }} parameters
   */
  constructor({
    id,
    userRef,
    accounts = {},
    essentials = {},
    assets = {},
    offboardByUserRef,
    timestamp = "",
  } = {}) {
    super();
    this.id = id;
    this.accounts = accounts;
    this.essentials = essentials;
    this.assets = assets;
    this.offboardByUserRef = offboardByUserRef;
    this.timestamp = timestamp;
    this.userRef = userRef;
  }
  async resolveRefs() {
    return Promise.all([this.resolveRef(User, "userRef")]);
  }
  getDate() {
    return DateTime.fromISO(this.timestamp).toISODate();
  }
}

<template>
  <div id="app">
    <loading-hero is-fullheight is-dark />
  </div>
</template>

<script>
import LoadingHero from "@/components/app/LoadingHero";
import { removeClass } from "@/utils";

export default {
  name: "LoadingApp",
  components: { LoadingHero },
  created() {
    removeClass(document.body, "has-navbar-fixed-bottom");
  },
};
</script>

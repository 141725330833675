<template>
  <nav
    class="navbar is-dark is-fixed-bottom"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand is-app-navbar">
      <!-- ? Router link is hidden on Touch devices. -->
      <router-link
        class="navbar-item is-hidden-touch"
        v-bind:to="{ name: 'Assets' }"
      >
        <figure class="image is-app-logo is-navbar-logo">
          <heaven-logo />
        </figure>
        <span>Heaven</span>
      </router-link>

      <!-- ? Anchor is hidden on Touch devices. -->
      <a
        role="button"
        class="navbar-burger is-hidden-touch"
        aria-label="menu"
        v-bind:aria-expanded="String(this.isNavbarOpen)"
        v-bind:class="navbarBurgerClasses"
        @click="onClickNavbarBurger"
      >
        <span aria-hidden="true" />
        <span aria-hidden="true" />
        <span aria-hidden="true" />
      </a>

      <!-- ? Div is hidden on Desktop devices. -->
      <div class="navbar-item is-touch-navbar-item is-hidden-desktop">
        <div class="tabs is-fullwidth is-toggle">
          <ul>
            <router-link
              v-bind:to="{ name: 'Assets' }"
              exact-active-class="is-active"
              v-slot="{ navigate, isExactActive }"
              custom
            >
              <li @click="navigate" :class="{ 'is-active': isExactActive }">
                <a href="javascript:">
                  <span class="icon is-small m-0">
                    <monstr-icon icon="archive" />
                  </span>
                  <span class="is-hidden-touch">Assets</span>
                </a>
              </li>
            </router-link>

            <router-link
              v-bind:to="{ name: 'Library' }"
              exact-active-class="is-active"
              v-slot="{ navigate, isExactActive }"
              custom
            >
              <li @click="navigate" :class="{ 'is-active': isExactActive }">
                <a href="javascript:">
                  <span class="icon is-small m-0">
                    <monstr-icon icon="gamepad" />
                  </span>
                  <span class="is-hidden-touch">Library</span>
                </a>
              </li>
            </router-link>

            <router-link
              v-bind:to="{ name: 'SupportRequests' }"
              exact-active-class="is-active"
              v-slot="{ navigate, isExactActive }"
              custom
            >
              <li @click="navigate" :class="{ 'is-active': isExactActive }">
                <a href="javascript:">
                  <number-of-notifications />
                  <span class="icon is-small m-0">
                    <monstr-icon icon="lifebuoy" />
                  </span>
                  <span class="is-hidden-touch">Support requests</span>
                </a>
              </li>
            </router-link>

            <router-link
              v-bind:to="{ name: 'Users' }"
              exact-active-class="is-active"
              v-slot="{ navigate, isExactActive }"
              custom
            >
              <li @click="navigate" :class="{ 'is-active': isExactActive }">
                <a href="javascript:">
                  <span class="icon is-small m-0">
                    <monstr-icon icon="users" />
                  </span>
                  <span class="is-hidden-touch">Users</span>
                </a>
              </li>
            </router-link>

            <router-link
              v-if="isAdmin"
              v-bind:to="{ name: 'Dashboard' }"
              exact-active-class="is-active"
              v-slot="{ navigate, isExactActive }"
              custom
            >
              <li @click="navigate" :class="{ 'is-active': isExactActive }">
                <a href="javascript:">
                  <span class="icon is-small m-0">
                    <monstr-icon icon="dashboard" />
                  </span>
                  <span class="is-hidden-touch">Dashboard</span>
                </a>
              </li>
            </router-link>

            <li>
              <logout-button class="is-touch-navbar-button">
                <span class="icon is-small m-0">
                  <monstr-icon icon="sign-out" />
                </span>
              </logout-button>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- ? Div is hidden on Touch devices. -->
    <div class="navbar-menu is-hidden-touch" :class="navbarMenuClasses">
      <div class="navbar-end">
        <router-link
          class="navbar-item"
          v-bind:to="{ name: 'Assets' }"
          exact-active-class="is-active"
          >Assets
        </router-link>

        <router-link
          class="navbar-item"
          v-bind:to="{ name: 'Library' }"
          exact-active-class="is-active"
          >Library
        </router-link>

        <router-link
          class="navbar-item"
          v-bind:to="{ name: 'SupportRequests' }"
          exact-active-class="is-active"
          >Support requests
          <number-of-notifications />
        </router-link>

        <router-link
          class="navbar-item"
          v-bind:to="{ name: 'Users' }"
          exact-active-class="is-active"
          >Users
        </router-link>

        <router-link
          v-if="isAdmin"
          class="navbar-item"
          v-bind:to="{ name: 'Dashboard' }"
          exact-active-class="is-active"
          >Dashboard
        </router-link>

        <div class="navbar-item">
          <logout-button />
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import HeavenLogo from "@/assets/heaven-logo.svg";
import LogoutButton from "@/components/auth/LogoutButton";
import MonstrIcon from "@/components/general/MonstrIcon";
import NumberOfNotifications from "@/components/widget/NumberOfNotifications";
import { AuthService } from "@/services";

export default {
  name: "TheNavbar",
  components: { NumberOfNotifications, MonstrIcon, HeavenLogo, LogoutButton },
  data() {
    return {
      isNavbarOpen: false,
    };
  },
  computed: {
    isAdmin() {
      return AuthService.isAdmin();
    },
    navbarBurgerClasses() {
      return {
        "is-active": this.isNavbarOpen,
      };
    },
    navbarMenuClasses() {
      return {
        "is-active": this.isNavbarOpen,
      };
    },
  },
  methods: {
    onClickNavbarBurger() {
      this.isNavbarOpen = !this.isNavbarOpen;
    },
    newFunction() {
      return 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar .tabs a {
  border: 0;
  color: lightgrey;
  padding-bottom: 0.8rem;
  padding-top: 0.8rem;
}
</style>

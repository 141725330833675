<template>
  <button class="button is-blob" @click="onClickLogout">
    <slot>Logout</slot>
  </button>
</template>

<script>
import { AuthUtils } from "@/utils";

export default {
  name: "LogoutButton",
  methods: {
    async onClickLogout() {
      await AuthUtils.logout();
      this.$router.replace({ name: "Home" });
    },
  },
};
</script>

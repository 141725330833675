// eslint-disable-next-line no-unused-vars
import { DateTime } from "luxon";

export class QuarterDateTime {
  /**
   * @param {{
   *  start: DateTime;
   *  end: DateTime;
   * }} parameters
   */
  constructor({ start, end }) {
    this.start = start;
    this.end = end;
  }

  get key() {
    return this.start.DATETIME_FULL;
  }

  toString(includeQuarter = true) {
    return includeQuarter
      ? this.start.toFormat("yyyy Qq")
      : this.start.toFormat("yyyy");
  }
}

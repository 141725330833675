/**
 * @class LogEntryData
 */
export class LogEntryData {
  /**
   * @param {{
   *  collectionId: string;
   *  documentRef: firestore.DocumentReference<firestore.DocumentData>;
   *  data: object;
   * }} parameters
   */
  constructor({ documentRef = null, collectionId = "", data = {} } = {}) {
    this.documentRef = documentRef;
    this.collectionId = collectionId;
    this.data = data;
  }
}

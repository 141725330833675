<template>
  <div id="app">
    <section class="hero is-fullheight">
      <div class="hero-header" />
      <div class="hero-body">
        <div class="container has-text-centered">
          <h2 class="subtitle">
            Only the awesome people at Softhouse is allowed through the pearly
            gates
          </h2>
          <h2 class="subtitle">
            <a href="https://www.softhouse.se/jobb/">Please let me in!</a>
          </h2>
        </div>
      </div>
      <div class="hero-footer" />
    </section>
  </div>
</template>

<script>
import { removeClass } from "@/utils";

export default {
  name: "InvalidDomainApp",
  created() {
    removeClass(document.body, "has-navbar-fixed-bottom");
  },
};
</script>

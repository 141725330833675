// eslint-disable-next-line no-unused-vars
import { ResourceType } from "@/models";

export class ResourcesFilter {
  /**
   *
   * @param {{
   *  searchTerm: string;
   *  type: ResourceType | "";
   *  location: Office[];
   * }} parameters
   */
  constructor({ searchTerm = "", type = "", location = [] } = {}) {
    this.searchTerm = searchTerm;
    this.type = type;
    this.location = location;
  }
}

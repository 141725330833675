import { DocumentResolver } from "@/models/DocumentResolver";

export class OfficeGroup extends DocumentResolver {
  /**
   * @param {{
   *  id: string;
   *  groupName: string;
   *  offices: firestore.DocumentReference<firestore.DocumentData>[];
   * }} parameters
   */
  constructor({ id = "", groupName = "", offices = [] } = {}) {
    super();
    this.id = id;
    this.groupName = groupName;
    this.offices = offices;
  }
}

import axios from "axios";
import { GoogleAuthProvider } from "firebase/auth";
import {
  addDoc,
  collection,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
} from "firebase/firestore";
import "@/firebase";
import { LogEntryData } from "@/models/LogEntryData";
import { AuthService, LogService, UsersService } from "@/services";

export const AuthUtils = new (class {
  /**
   * @private
   */
  unsubscribeUserRoleListener = null;

  /**
   * @private
   */
  userSnapshotLocationListener = () => undefined;

  /**
   * @returns {Promise<void>}
   */
  async logout() {
    if (this.unsubscribeUserRoleListener) this.unsubscribeUserRoleListener();
    await AuthService.logout();
  }

  async getOffices() {
    return await getDocs(collection(getFirestore(), "offices"));
  }

  async addNewOfficeLocation(officeHRV) {
    let officeObject = {
      HRV: officeHRV.charAt(0).toUpperCase() + officeHRV.slice(1),
      CRV: officeHRV
        .toUpperCase()
        .normalize("NFKD")
        .replace(/[\u0300-\u036F]/g, "")
        .replace(/\W/g, ""),
    };
    let officeCollection = collection(getFirestore(), "offices");
    await LogService.create(
      new LogEntryData({
        officeObject,
        collectionId: officeCollection.id,
      }),
    );
    return addDoc(officeCollection, officeObject);
  }

  /**
   * Retrieves the users location from Google.
   * @param {string} accessToken
   * @returns {Promise<string>}
   */
  async retrieveUserOfficeLocation(accessToken) {
    if (accessToken === "FirebaseAuthEmulatorFakeAccessToken_google.com") {
      return "Karlskrona";
    }
    const instance = axios.create();
    const response = await instance.get("people/me", {
      baseURL: "https://people.googleapis.com/v1/",
      timeout: 1000,
      headers: {
        authorization: "Bearer " + accessToken,
        accept: "application/json",
      },
      params: {
        personFields: "organizations",
      },
    });
    return response.data.organizations[0].location;
  }

  /**
   * Updates the logged-in users location.
   * @param {string} accessToken
   * @returns {void}
   */
  async updateUserLocation(accessToken) {
    this.userSnapshotLocationListener = await this.getUserDoc().then(
      async (user) => {
        if (user.data()?.email) {
          const updatedUser = UsersService.toDocClass(user);
          let officeString = await this.retrieveUserOfficeLocation(accessToken);
          let offices = await this.getOffices();
          let office = offices.docs.find(
            (doc) =>
              doc.data().HRV.toLowerCase() === officeString.toLowerCase(),
          );
          if (office) {
            updatedUser.officeLocation = office.ref;
          } else {
            updatedUser.officeLocation =
              await this.addNewOfficeLocation(officeString);
          }
          await UsersService.updateOne(updatedUser);
        }
      },
    );
  }

  async getUserDoc() {
    return await getDoc(UsersService.getDocById(AuthService.getUserId()));
  }

  /**
   * Catches the login redirect from Firebase Auth.
   * @returns {Promise<void>}
   */
  async catchRedirect() {
    const result = await AuthService.getRedirectResult();
    if (result && result.user !== null) {
      const user = await UsersService.getOne(AuthService.getUserId());
      if (!user?.officeLocation) {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        await this.updateUserLocation(credential.accessToken);
      }
    }
  }

  /**
   * Update the internal state with the current role.
   * @returns {Promise<void>}
   */
  async updateUserState() {
    const { claims } = await AuthService.getUser().getIdTokenResult(true);
    AuthService.updateUserState(claims.role);

    if (!this.unsubscribeUserRoleListener) {
      this.unsubscribeUserRoleListener = this.userRoleListener();
    }
  }

  userRoleListener() {
    const getDoc = UsersService.getDocById(AuthService.getUserId());
    return onSnapshot(getDoc, async (user) => {
      if (user.data()?.role) {
        const { role } = user.data();
        if (AuthService.getState().role !== role) {
          await this.updateUserState();
          window.location.reload();
        }
      }
    });
  }
})();

import { AuthService } from "@/services/AuthService";
import { FirestoreService } from "@/services/FirestoreService";

export class OffboardChecklistFirestoreService extends FirestoreService {
  async getOffboardedUserInformation(userRef) {
    if (AuthService.isAdmin()) {
      return this.getAllWhere("userRef", "==", userRef);
    }
  }
}

import { getDocs, query, where } from "firebase/firestore";
import {
  // eslint-disable-next-line no-unused-vars
  SupportRequestsFilter,
  UserRole,
} from "@/models";
import {
  AuthService,
  CacheService,
  SupportRequestsService,
  UsersService,
} from "@/services";

class SupportRequestsUtilsClass {
  /**
   *
   * @param {SupportRequestsFilter} filter
   */
  async getAllFromSupportRequestsFilter(filter) {
    const whereClauses = [];

    if (AuthService.isUser()) {
      whereClauses.push(
        where(
          "assetUserRef",
          "==",
          UsersService.getDocById(AuthService.getUserId()),
        ),
      );
    }
    if (AuthService.getUserRole() !== UserRole.ADMIN) {
      const user = await UsersService.getOne(AuthService.getUserId());

      if (user.administeredOffices.length > 1) {
        whereClauses.push(
          where("officeLocation", "in", user.administeredOffices),
        );
      } else {
        whereClauses.push(where("officeLocation", "==", user.officeLocation));
      }
    }

    if (filter.status.length > 0) {
      whereClauses.push(where("status", "in", filter.status));
    }

    if (filter.type) {
      whereClauses.push(where("type", "==", filter.type));
    }

    const q = query(SupportRequestsService.getCollection(), ...whereClauses);
    const resolvedDocs = await getDocs(q);
    let supportRequests = SupportRequestsService.toDocClasses(resolvedDocs);

    if (filter.requestedBy) {
      let users = await CacheService.getUsers();
      users = users.filter((user) => {
        return user.displayName
          .toLowerCase()
          .includes(filter.requestedBy.toLowerCase());
      });
      let supportRequestUserReferences =
        await this.getUserReferences(supportRequests);
      let userIds = users.map((user) => user.id);
      supportRequestUserReferences = supportRequestUserReferences.filter(
        (supportRequestUserRef) => {
          return userIds.includes(supportRequestUserRef);
        },
      );
      supportRequests = supportRequests.filter((s) =>
        supportRequestUserReferences.includes(s.userRef.id),
      );
    }

    if (filter.resourceSearch) {
      const resources = CacheService.getResources().filter((resource) => {
        return (resource.manufacturer + " " + resource.model)
          .toLowerCase()
          .includes(filter.resourceSearch.toLowerCase());
      });
      const assetIds = CacheService.getAssets()
        .filter((asset) =>
          resources.find((resource) => resource.id === asset.resourceRef.id),
        )
        .map((asset) => asset.id);

      const supportRequestAssetReferences = (
        await this.getAssetReferences(supportRequests)
      ).filter((supportRequestAssetRef) => {
        return assetIds.includes(supportRequestAssetRef);
      });
      supportRequests = supportRequests.filter((supportRequest) =>
        supportRequestAssetReferences.includes(supportRequest.assetRef.id),
      );
    }

    if (filter.leader) {
      const userIds = (
        await UsersService.getUsersByLeaderId(filter.leader)
      ).map((user) => {
        return user.id;
      });

      supportRequests = supportRequests.filter((supportRequest) =>
        userIds.includes(supportRequest?.assetUserRef?.id),
      );
    }

    return supportRequests;
  }

  async getUserReferences(supportRequests) {
    return supportRequests
      .map((s) => (s.userRef = CacheService.getUser(s.requestedByUserRef)))
      .map((u) => u.id);
  }

  async getAssetReferences(supportRequests) {
    return supportRequests
      .map((supportRequest) => CacheService.getAsset(supportRequest.assetRef))
      .map((resource) => resource.id);
  }
}

export const SupportRequestsUtils = new SupportRequestsUtilsClass();

import { getDocs, query, where, orderBy } from "firebase/firestore";
import { FirestoreService } from "@/services/FirestoreService";
import { UsersService } from "@/services/UsersService";

export class ChangelogFirestoreService extends FirestoreService {
  getChangelogs() {
    return this.getAll();
  }

  /**
   * @param {string} userId
   */
  getUserChangelogs(userId) {
    return this.getAllWhere(
      "data.userRef",
      "==",
      UsersService.getDocById(userId),
    );
  }

  /**
   * @param {Asset} asset
   */
  async getAssetChangelogs(asset) {
    const q = query(
      this.getCollection(),
      where("data.serialNumber", "==", asset.serialNumber),
      orderBy("timestamp", "desc"),
    );
    const docs = await getDocs(q);
    return this.toDocClasses(docs);
  }
}

import { DateTime } from "luxon";

/**
 * @enum {string} OperationType
 */
export const OperationType = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
};

/**
 * @class LogEntry
 */
export class LogEntry {
  /**
   * @param {{
   *  userRef: firestore.DocumentReference<firestore.DocumentData>;
   *  collectionId: string;
   *  documentRef: firestore.DocumentReference<firestore.DocumentData>;
   *  operation: OperationType;
   *  data: object;
   *  timestamp: string;
   * }} parameters
   */
  constructor({
    documentRef = null,
    userRef = null,
    collectionId = "",
    operation = "",
    data = {},
    timestamp = "",
  } = {}) {
    this.documentRef = documentRef;
    this.userRef = userRef;
    this.collectionId = collectionId;
    this.operation = operation;
    this.data = data;
    this.timestamp = timestamp;
  }

  getDate() {
    return DateTime.fromISO(this.timestamp).toISODate();
  }
}

import { FirestoreService } from "@/services/FirestoreService";

export class ResourcesFirestoreService extends FirestoreService {
  /**
   *  Overrides our generic getAll function to not filter out resources by office location
   */
  async getAll() {
    return super.getAll(true);
  }
}
